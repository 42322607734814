<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-date-picker
          v-model="listQuery.beginTime"
          type="date"
          placeholder="开始时间"
          value-format="YYYY-MM-DD"
          size="small"
          style="width: 100px"
        /> &nbsp;-&nbsp;
        <el-date-picker
          v-model="listQuery.endTime"
          type="date"
          placeholder="结束时间"
          value-format="YYYY-MM-DD"
          size="small"
          style="width: 100px"
        />
        <el-button size="small" type="primary" icon="Search" @click="getList" style="margin-left: 3px;">搜索</el-button>
      </div>
      <div style="height: 30px;display: flex;align-items: center;">
        <el-button
          @click="add()"
          type="primary"
          size="small"
          >添加</el-button
        >
      </div>
    </div>
    <el-table
      :data="list"
      border
      style="width: 100%"
      v-loading="loading"
      :height="contentStyleObj"
      @selection-change="handleSelectionChange"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <!-- <el-table-column align="center" type="selection" width="55"> </el-table-column> -->
      <el-table-column type="index" label="序号" width="55" align="center" fixed/>
      <el-table-column prop="money" label="金额（元）" align="center" min-width="400" fixed>
      </el-table-column>
      <el-table-column prop="created_at" label="登记时间" align="center" min-width="400" fixed>
      </el-table-column>
      <el-table-column prop="count" label="操作" align="center">
        <template #default="{ row }">
          <el-button
            size="small"
            type="primary"
            @click="del(row)"
            >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="pagination">
    <qzf-pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.pageSize"
      @pagination="getList"
    />
  </div>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="添加"
    v-model="addShow"
    width="21.5%"
  >
    <el-form size="small" :model="form" :rules="rules" ref="ruleFormRef" label-position="right"   label-width="80px">
      <el-form-item label="金额" prop="money">
        <el-input v-model="form.money" style="width: 240px;">
          <template #append>元</template>
        </el-input> 
      </el-form-item>
      <div style="font-size: 12px;margin-top: 20px;color: #B9B9B9;">
        提示：请如实填写实缴资本金额，我们将按照您提交的金额申报印花税
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="addShow = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="save"
          :loading="saveLoading"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted,getCurrentInstance } from "vue";
import { ElMessage,ElMessageBox  } from "element-plus";
import { findPeople } from "@/api/base";
import { xcxComPaildCapitalList,createPaildCapital,deleteCapital } from "@/api/company"
const { proxy } = getCurrentInstance();
const ruleFormRef = ref();
const loading = ref(false)
const addShow = ref(false)
const saveLoading = ref(false)
const total = ref(0)
const list = ref([])
const sels = ref([])
const ids = ref([])
const type = ref('')
const form = ref({
})
const listQuery = ref({
  page: 1,
  limit: 20,
  beginTime: '',
  endTime: '',
})
const adManageList = ref([
  {
    value: 'pc',
    label: '全部',
  },
  {
    value: 'pc',
    label: '文字',
  },
  {
    value: 'mobile',
    label: '图片',
  },
]);

const contentStyleObj = ref({});//列表高度
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(250);
  getList();
});
const getList = () => {
  loading.value = true;
  xcxComPaildCapitalList(listQuery.value).then((res) =>{
    console.log(res);
    loading.value = false;
    if(res.data.msg == 'success'){
      list.value = res.data.data.list
      total.value = res.data.data.total
      if(list.value.length > 0){
        list.value.forEach((file) =>{
          file.created_at = file.created_at.split('T')[0]
        })
      }
    }
  })
};
const add = () =>{
  form.value = {
    money: '',
  }
  addShow.value = true
}
const save = () =>{
  if(!form.value.money){
    ElMessage.error('请填写金额');
    return
  }
  saveLoading.value = true;
  createPaildCapital(form.value).then((res) =>{
    console.log(res);
    saveLoading.value = false;
    if(res.data.msg == 'success'){
      ElMessage.success('添加成功');
      addShow.value = false;
      getList()
    }
  })
}
const del = (val) =>{
  ElMessageBox.confirm(
    '确定要删除金额为'+val.money,
    '删除',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: '确定',
    }
  )
  .then(() => {
    deleteCapital({id:val.id}).then((res) => {
      if(res.data.msg =='success'){
        ElMessage.success("删除成功");
        getList();
      }
    })
  })
  .catch(() => {
  })
}

const handleSelectionChange = (e) =>{
  sels.value = e;
  ids.value = sels.value.map((v) => v.id)
}
const reset = () =>{
  from.value = {
  }
}


</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w250{
  width: 250px;
}
.pagination {
  margin-top: 16px;
  float: right;
}
</style>
